import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SupportedLanguagesApiService } from '@booking-booster-client/api';
import { supportedLanguagesActionGroup } from './supported-languages.actions';

@Injectable()
export class SupportedLanguagesEffects {
  constructor(
    private actions$: Actions,
    private supportedLanguageService: SupportedLanguagesApiService,
  ) {}

  loadOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportedLanguagesActionGroup.loadRequest),
      switchMap(() =>
        this.supportedLanguageService.loadSupportedLanguages().pipe(
          map((data) => {
            return supportedLanguagesActionGroup.loadSuccess({
              locals: data,
            });
          }),
          catchError((error) => {
            return of(supportedLanguagesActionGroup.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
