import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataAccommodation } from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class AccomodationApiService {
  constructor(private http: HttpClient) {}

  loadAccommodations(): Observable<DataAccommodation> {
    return this.http.get<DataAccommodation>(`api/accommodations`);
  }
}
