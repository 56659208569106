import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  RegisterResponse,
  RegistrationUser,
  User,
} from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  register(registrationUser: RegistrationUser): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`api/users/register`, {
      name: registrationUser.name,
      surname: registrationUser.surname,
    });
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`api/users/me`);
  }
}
