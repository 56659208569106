import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { ChannelApiService } from '@booking-booster-client/api';
import { channelActionsGroup } from './channel.actions';
import { effectHooks } from '@booking-booster-client/helpers';
import { HandlerMessageService } from '../../services/handler-message.service';

@Injectable()
export class ChannelEffects {
  constructor(
    private actions$: Actions,
    private channelService: ChannelApiService,
    private handlerMessageService: HandlerMessageService,
  ) {}

  loadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(channelActionsGroup.loadRequest),
      switchMap(() =>
        this.channelService.getChannels().pipe(
          map((data) => {
            return channelActionsGroup.loadSuccess({
              dataChannels: data,
            });
          }),
          catchError((error) => {
            this.handlerMessageService.showMessageError('load_fail', {
              name: 'channels',
            });
            return of(channelActionsGroup.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  connection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(channelActionsGroup.connectionRequest),
      switchMap(({ request, hooks }) =>
        this.channelService.connection(request).pipe(
          effectHooks(hooks),
          map((currentConnection) => {
            this.handlerMessageService.showMessageSuccess('connection_success');
            return channelActionsGroup.connectionSuccess({ currentConnection });
          }),
          catchError((error) => {
            effectHooks(hooks);
            this.handlerMessageService.showMessageError('connection_fail');
            return of(channelActionsGroup.connectionFailure(error));
          }),
        ),
      ),
    ),
  );

  setChannelId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(channelActionsGroup.setIdRequest),
      map(({ channelId }) => {
        return channelActionsGroup.setIdSuccess({
          channelId,
        });
      }),
      catchError((error) => {
        return of(channelActionsGroup.setIdFailure(error));
      }),
    ),
  );

  addAccommodation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(channelActionsGroup.addAccommodationsRequest),
      switchMap(({ accommodations, hooks }) =>
        forkJoin(
          accommodations.map((addAccommodationsRequest) =>
            this.channelService.addAccomodation(addAccommodationsRequest),
          ),
        ).pipe(
          effectHooks(hooks),
          map(() => {
            this.handlerMessageService.showMessageSuccess(
              'accommodations.add_success',
            );
            return channelActionsGroup.addAccommodationsSuccess();
          }),
          catchError((error) => {
            this.handlerMessageService.showMessageError(
              'accommodations.add_error',
            );
            return of(channelActionsGroup.addAccommodationsFailure(error));
          }),
        ),
      ),
    ),
  );

  getAccommodations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(channelActionsGroup.getAccommodationsRequest),
      switchMap(({ connectionId }) =>
        this.channelService.getAccomodations(connectionId).pipe(
          map((dataAccommodations) => {
            return channelActionsGroup.getAccommodationsSuccess({
              accommodations: dataAccommodations.accommodations,
            });
          }),
          catchError((error) => {
            this.handlerMessageService.showMessageError('load_fail', {
              name: 'properties',
            });
            return of(channelActionsGroup.getAccommodationsFailure(error));
          }),
        ),
      ),
    ),
  );
}
