import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStateModule } from './user/user-state.module';
import { PropertiesTagsStateModule } from './properties-tags/properties-tags-state.module';
import { AccommodationsStateModule } from './accommodations/accommodations-state.module';
import { SearchEnginesStateModule } from './search-engines/search-engines-state.module';

@NgModule({
  imports: [
    CommonModule,
    UserStateModule,
    AccommodationsStateModule,
    PropertiesTagsStateModule,
    SearchEnginesStateModule,
  ],
})
export class CommonStatesModule {}
