import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DataChannels,
  Accommodation,
  DataAccomodations,
  AccommodationRequest,
  OnBoardCurrentConnection,
} from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class ChannelApiService {
  constructor(private http: HttpClient) {}

  getChannels(): Observable<DataChannels> {
    return this.http.get<DataChannels>(`api/channels`);
  }

  connection(
    request: OnBoardCurrentConnection,
  ): Observable<OnBoardCurrentConnection> {
    return this.http.post<OnBoardCurrentConnection>(
      `api/channels/connections`,
      request,
    );
  }

  getAccomodations(connectionId: string): Observable<DataAccomodations> {
    return this.http.get<DataAccomodations>(
      `api/channels/connections/${connectionId}/accommodations`,
    );
  }

  addAccomodation(accommodation: AccommodationRequest) {
    return this.http.post<Accommodation>(`api/accommodations`, accommodation);
  }

  editAccomodation(id: string, accommodation: Partial<AccommodationRequest>) {
    return this.http.put<Accommodation>(
      `api/accommodations/${id}`,
      accommodation,
    );
  }
}
