import { createReducer, on, Action } from '@ngrx/store';

import * as Actions from './properties-tags.actions';
import { featureAdapter, initialState, State } from './properties-tags.state';

const reducerFunction = createReducer(
  initialState,
  on(Actions.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.loadSuccess, (state, { propertiesTags }) =>
    featureAdapter.setAll(propertiesTags, {
      ...state,
      loading: false,
    })
  ),
  on(Actions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(Actions.reset, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
