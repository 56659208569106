import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStateModule } from './user/user-state.module';
import { PropertiesStateModule } from './properties/properties-state.module';
import { PropertiesTagsStateModule } from './properties-tags/properties-tags-state.module';

@NgModule({
  imports: [
    CommonModule,
    UserStateModule,
    PropertiesStateModule,
    PropertiesTagsStateModule,
  ],
})
export class CommonStatesModule {}
