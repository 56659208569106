import { ResolveFn } from '@angular/router';
import { filter, of, switchMap } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { inject } from '@angular/core';
import { ORGANIZATION_SELECTED } from '../constants-file';
import { DialogService } from 'primeng/dynamicdialog';
import { Nullable } from '@booking-booster-client/models';
import { State as AuthState } from '../root-store/organization-state/organization.state';
import { Store, select } from '@ngrx/store';
import * as OrganizationSelectors from '../root-store/organization-state/organization.selectors';
import { organizationActionsGroup } from '../root-store/organization-state/organization.actions';
import { OrganizationSelectComponent } from '../modules/signin/organization-select/organization-select.component';
import { TranslateService } from '@ngx-translate/core';
import { UpperFirstPipe } from '@z-trippete/angular-pipes';
import { OrganizationApiService } from '@booking-booster-client/api';

export const selectedOrganizationResolver: ResolveFn<Nullable<string>> = () => {
  const localStorageService = inject(LocalStorageService);
  const dialogService = inject(DialogService);
  const store = inject(Store<AuthState>);
  const traslateService = inject(TranslateService);
  const upperFirst = inject(UpperFirstPipe);
  const organizationsApiService = inject(OrganizationApiService);

  store.dispatch(organizationActionsGroup.loadRequest());

  const organizationId$ = store.pipe(
    select(OrganizationSelectors.selectOrganizationId),
    filter((organization) => !!organization),
  );

  const organizationId: string = localStorageService.getItem(
    ORGANIZATION_SELECTED,
  );

  return organizationsApiService.getOrganizations().pipe(
    switchMap((dataOrganizations) => {
      if (
        dataOrganizations.organizations.some(
          (organization) => organization.organizationId === organizationId,
        )
      ) {
        store.dispatch(
          organizationActionsGroup.setIdRequest({
            organizationId,
          }),
        );

        return of(organizationId);
      }

      return dialogService
        .open(OrganizationSelectComponent, {
          header: upperFirst.transform(
            traslateService.instant('select_organization'),
          ),
          width: '600px',
          height: 'auto',
          closable: false,
        })
        .onChildComponentLoaded.pipe(switchMap((c) => c.closedChange$))
        .pipe(switchMap(() => organizationId$));
    }),
  );
};
