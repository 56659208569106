import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportedLanguagesApiService {
  constructor(private http: HttpClient) {}

  loadSupportedLanguages(): Observable<string[]> {
    return this.http.get<string[]>(`api/systems/supportedLanguages`);
  }
}
