import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationStoreModule } from './organization-state/organization-store.module';
import { SupportedLanguagesStoreModule } from './supported-languages/supported-languages.module';

@NgModule({
  imports: [
    CommonModule,
    OrganizationStoreModule,
    SupportedLanguagesStoreModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot(),
  ],
})
export class RootStoreModule {}
