import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, of } from 'rxjs';
import { LoadServicesRequest, Service } from '@booking-booster-client/models';

const SERVICES: Service[] = [
  {
    id: 1,
    name: 'Wifi Gratuito',
    icon: 'far fa-wifi',
    color: '#89b415',
  },
  {
    id: 2,
    name: 'Parcheggio Gratuito',
    icon: 'far fa-parking',
    color: '#084d21',
  },
  {
    id: 3,
    name: 'SPA',
    icon: 'far fa-spa',
    color: '#5e58d8',
  },
  {
    id: 4,
    name: 'Spa Privata',
    icon: 'far fa-hot-tub',
    color: '#c8279f',
  },
  {
    id: 5,
    name: 'Percorsi Benessere',
    icon: 'far fa-mug-tea',
    color: '#c1c7a6',
  },
  {
    id: 6,
    name: 'Late Check-Out',
    icon: 'far fa-alarm-exclamation',
    color: '#5c3efe',
  },
  {
    id: 7,
    name: 'Ristorante',
    icon: 'far fa-utensils',
    color: '#9ac2ac',
  },
  {
    id: 8,
    name: 'Bar',
    icon: 'far fa-glass-martini',
    color: '#c9ee41',
  },
  {
    id: 9,
    name: 'Piscina',
    icon: 'far fa-swimmer',
    color: '#70494e',
  },
  {
    id: 10,
    name: 'Piscina al chiuso',
    icon: 'far fa-swimming-pool',
    color: '#217c57',
  },
  {
    id: 11,
    name: 'Mini Club',
    icon: 'far fa-club',
    color: '#1ae63f',
  },
  {
    id: 12,
    name: 'Sala Meeting',
    icon: 'far fa-users-class',
    color: '#dc79e4',
  },
];

@Injectable({
  providedIn: 'root',
})
export class ServicesApiService {
  constructor(private httpClient: HttpClient) {}

  // [GET] services
  getIndex(request: LoadServicesRequest) {
    const data = SERVICES.filter((event) => {
      if (!request.search) {
        return true;
      }

      return event.name.toLowerCase().includes(request.search.toLowerCase());
    });

    return of({
      data,
    }).pipe(delay(500));
  }
}
