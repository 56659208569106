import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AccommodationRequest,
  DataChannels,
  EffectHooks,
  Nullable,
  OnBoardAccommodation,
  OnBoardCurrentConnection,
} from '@booking-booster-client/models';

export const channelActionsGroup = createActionGroup({
  source: 'Channel',
  events: {
    'Load Request': emptyProps,

    'Load Success': props<{
      dataChannels: DataChannels;
    }>(),

    'Load Failure': props<{
      error: unknown;
    }>(),

    'Connection Request': props<{
      request: any;
      hooks?: EffectHooks;
    }>(),

    'Connection Success': props<{
      currentConnection: OnBoardCurrentConnection;
    }>(),

    'Connection Failure': props<{
      error: unknown;
      hooks?: EffectHooks;
    }>(),

    'Get Accommodations Request': props<{
      connectionId: string;
    }>(),

    'Get Accommodations Success': props<{
      accommodations: OnBoardAccommodation[];
    }>(),

    'Get Accommodations Failure': props<{
      error: unknown;
    }>(),

    'Add Accommodations Request': props<{
      accommodations: AccommodationRequest[];
      hooks?: EffectHooks;
    }>(),

    'Add Accommodations Success': emptyProps,

    'Add Accommodations Failure': props<{
      error: unknown;
      hooks?: EffectHooks;
    }>(),

    'Set Id Request': props<{
      channelId: Nullable<string>;
    }>(),

    'Set Id Success': props<{
      channelId: Nullable<string>;
    }>(),

    'Set Id Failure': props<{
      error: unknown;
    }>(),
  },
});
