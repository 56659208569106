import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserApiService } from '@booking-booster-client/api';
import { effectHooks } from '@booking-booster-client/helpers';
import * as fromActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userApiService: UserApiService
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ hooks }) =>
        this.userApiService.load().pipe(
          effectHooks(hooks),
          map((user) => {
            return fromActions.loadSuccess({ user });
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          })
        )
      )
    )
  );
}
