import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccommodationsService } from '@booking-booster-client/api';
import { accommodationTagsActions as fromActions } from './properties-tags.actions';
import { effectHooks } from '@booking-booster-client/helpers';
import { HandlerMessageService } from '../../services/handler-message.service';

@Injectable()
export class PropertiesTagsEffects {
  constructor(
    private actions$: Actions,
    private propertiesApiService: AccommodationsService,
    private messageService: HandlerMessageService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() =>
        this.propertiesApiService.getTags().pipe(
          map(({ tags }) => {
            return fromActions.loadSuccess({
              propertiesTags: tags,
            });
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, hooks }) =>
        this.propertiesApiService.createTag(request).pipe(
          effectHooks(hooks),
          map((tag) => {
            this.messageService.showMessageSuccess(
              'notify_messages.add_succeded',
              {
                name: 'tag',
              },
            );
            return fromActions.createSuccess({
              tag,
            });
          }),
          catchError((error) => {
            this.messageService.showMessageSuccess('notify_messages.add_fail', {
              name: 'tag',
            });
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );
}
