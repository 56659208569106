import { createReducer, on, Action } from '@ngrx/store';

import * as UserActions from './user.actions';
import { initialState, State } from './user.state';

const reducerFunction = createReducer(
  initialState,
  on(UserActions.loadRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(UserActions.loadSuccess, (state, { user }) => ({
    ...state,
    user,
  })),
  on(UserActions.loadFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
