/* eslint-disable @typescript-eslint/no-unused-vars */
import { Property } from '@booking-booster-client/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Nullable } from 'subsink/dist/subsink';
import { USER_FEATURE_KEY, State, featureAdapter } from './properties.state';

export const selectState = createFeatureSelector<State>(USER_FEATURE_KEY);

export const { selectAll } = featureAdapter.getSelectors(selectState);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectPagination = createSelector(
  selectState,
  (state: State) => state.pagination,
);

export const selectSelectedProperties = createSelector(
  selectState,
  (state: State) => state.selectedProperties,
);

export const selectPropertiesIDS = createSelector(
  selectState,
  (state: State) => state.propertiesIDS,
);

export const selectSelectedPropertiesIDS = createSelector(
  selectSelectedProperties,
  (selectedProperties: Nullable<Record<string, Partial<Property>>>) =>
    Object.keys(selectedProperties || {}).map(String),
);
