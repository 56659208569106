import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromReducer from './channel.reducer';
import { ChannelsStore } from '.';
import { ChannelEffects } from './channel.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ChannelsStore.FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([ChannelEffects]),
  ],
})
export class ChannelStoreModule {}
