import { PropertyTag } from '@booking-booster-client/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'propertiesTags';

export const featureAdapter = createEntityAdapter<PropertyTag>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<PropertyTag> {
  loading: boolean;
  error: unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  loading: false,
  error: null,
});
