import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromReducer from './organization.reducer';
import { OrganizationEffects } from './organization.effects';
import { OrganizationStore } from '.';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(OrganizationStore.FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([OrganizationEffects]),
  ],
})
export class OrganizationStoreModule {}
