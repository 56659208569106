import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://accounts.google.com',
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin,
    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: '1067368616967-js2h953pipac4em33cml387chv44t4rd.apps.googleusercontent.com',
    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',
    responseType: 'code token id_token',
    oidc: true,
    requestAccessToken: true,
    dummyClientSecret: 'test',
    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'openid profile email',
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: true,
    //silentRefreshTimeout: 5000, // For faster testing
    clearHashAfterLogin: false,
    //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    //disableIdTokenTimer: true,
    useSilentRefresh: true, // Needed for Code Flow with Refresh Tokens
    //timeoutFactor: 0.75, // Start refresh process 25% before token expiration
    // Aggiungi il parametro access_type qui:
    customQueryParams: {
        access_type: 'offline',
    },
    logoutUrl: "https://accounts.google.com"
};