import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, Observable, of } from 'rxjs';
import {
  ApiResponse,
  CreateOnBoardConnectionRequest,
  OnBoardConnection,
} from '@booking-booster-client/models';
import { uniqueId } from 'lodash';
import { HOTELS } from './properties-api.service';
import { paginate } from '../../fake-api-response';

@Injectable({
  providedIn: 'root',
})
export class OnBoardApiService {
  constructor(private httpClient: HttpClient) {}

  // [POST] onboard/connection
  connect(
    request: CreateOnBoardConnectionRequest,
  ): Observable<ApiResponse<OnBoardConnection>> {
    const connectionID = +uniqueId();

    return of({
      data: {
        id: connectionID,
        source_type: request.source_type,
        source_id: request.source_id,
        properties: Object.values(HOTELS).map((hotel) => ({
          id: hotel.id,
          name: hotel.name,
          country: hotel.country,
          state: hotel.state,
          county: hotel.county,
          city: hotel.city,
          address: hotel.address,
          zip_code: hotel.zip_code,
          connection_id: connectionID,
        })),
      },
      meta: paginate([], 1, 10),
    }).pipe(delay(1000));
  }
}
