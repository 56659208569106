import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Subject } from 'rxjs';
import { LoadPoisRequest } from '@booking-booster-client/models';
import { TranslateService } from '@ngx-translate/core';
import { sortBy, uniqBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PoisApiService {
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}

  // [GET] pois
  getIndex(request: LoadPoisRequest) {
    const div = document.createElement('div');

    const gmap = new google.maps.Map(div, {
      center: { lat: request.lat, lng: request.lng },
      zoom: 15,
    });

    const service = new google.maps.places.PlacesService(gmap);

    return forkJoin(
      ...request.categories.map((category) => {
        const response = new Subject<google.maps.places.PlaceResult[]>();
        let data: google.maps.places.PlaceResult[] = [];

        service.nearbySearch(
          {
            location: { lat: request.lat, lng: request.lng },
            radius: 2000,
            keyword: request.search,
            language: this.translate.currentLang,
            type: category,
          },
          (places, _, pagination) => {
            data = [
              ...data,
              ...(places?.filter((place) => !!place.place_id) || []),
            ];

            if (pagination?.hasNextPage) {
              pagination.nextPage();
              return;
            }

            response.next(data);
            response.complete();
          }
        );

        return response.asObservable();
      })
    ).pipe(
      map((responses) => {
        return {
          data: sortBy(
            uniqBy(
              responses.reduce((data, response) => [...data, ...response], []),
              'place_id'
            ),
            'name'
          ).map((place, index) => ({
            id: index + 1,
            place_id: place.place_id || '',
            name: place.name || '',
            icon: place.icon || '',
            address: place.vicinity || '',
            color: place.icon_background_color || '',
            location: place.geometry?.location?.toJSON(),
          })),
        };
      })
    );
  }
}
