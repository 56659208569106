import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataOrganization, Organization } from '@booking-booster-client/models';

@Injectable({
  providedIn: 'root',
})
export class OrganizationApiService {
  constructor(private http: HttpClient) {}

  createOrganization(organizationName: string): Observable<Organization> {
    return this.http.post<Organization>(`api/Organizations`, {
      organizationName,
    });
  }

  getOrganizations(): Observable<DataOrganization> {
    return this.http.get<DataOrganization>(`api/Organizations`);
  }
}
