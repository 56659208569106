import { OnBoardConnectionSource } from '../types';
import { FormControl } from '@angular/forms';
import { Accommodation } from './accommodation.model';

export interface OnBoardConnection {
  id: number;
  accommodations: Partial<Accommodation>[];
  source_type: OnBoardConnectionSource | null | undefined;
  source_id: string | null;
}
export interface ConnectionParamForm {
  value: FormControl<string>;
  key: FormControl<string>;
  type: FormControl<string>;
}
