import { CanActivateFn, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { catchError, from, of, switchMap } from 'rxjs';
import { AuthApiService } from '@booking-booster-client/api';
import { HandlerMessageService } from '../services/handler-message.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private authService: AuthApiService,
    private handlerMessageService: HandlerMessageService,
  ) {}

  public get identityClaims() {
    return this.oauthService.getIdentityClaims() as { email: string };
  }

  public get email() {
    const claims = this.identityClaims;
    return claims ? claims.email : null;
  }

  canActivate: CanActivateFn = () => {
    return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      switchMap(() => {
        if (!this.oauthService.hasValidIdToken()) {
          return of(false);
        }

        return this.authService.getUserMe().pipe(
          switchMap(() => {
            return of(true);
          }),
          catchError(() => {
            this.handlerMessageService.showMessageError(
              'register.user_me_fail',
            );
            const queryParams = { email: this.email };
            return of(this.router.createUrlTree(['/signin'], { queryParams }));
          }),
        );
      }),
    );
  };
}
