import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards';
import { selectedOrganizationResolver } from './resolvers/selected-organization-resolver.resolve';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin-container/admin-container.module').then(
        (m) => m.AdminContainerModule,
      ),
  },
  {
    path: 'onboard',
    loadChildren: () =>
      import('./modules/property-onboard/onboard.module').then(
        (m) => m.OnboardModuleWithRouting,
      ),
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./modules/signin/signin.module').then((m) => m.SigninModule),
  },
  {
    path: 'simulator',
    loadChildren: () =>
      import('./modules/simulator/simulator.module').then(
        (m) => m.SimulatorModule,
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      organizationId: selectedOrganizationResolver,
    },
    loadChildren: () =>
      import('./modules/user-container/user-container.module').then(
        (m) => m.UserContainerModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
