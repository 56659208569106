import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromState from './properties.state';
import * as fromReducer from './properties.reducer';
import { PropertiesEffects } from './properties.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.USER_FEATURE_KEY, fromReducer.reducer),
    EffectsModule.forFeature([PropertiesEffects]),
  ],
})
export class PropertiesStateModule {}
