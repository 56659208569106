import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  Activity,
  ApiResponse,
  ListResponse,
  LoadActivityTableRequest,
} from '@booking-booster-client/models';
import { paginate } from '../../fake-api-response';

@Injectable({
  providedIn: 'root',
})
export class ActivityApiService {
  constructor(private http: HttpClient) {}

  loadActivities(request: LoadActivityTableRequest): Observable<
    ApiResponse<{
      rows: Activity[];
    }>
  > {
    return this.http.get<ListResponse<Activity>>(`api/activities`).pipe(
      map((records) => {
        const { data, pagination } = paginate(
          this.populateCurrentName(records.items),
          request.page || 1,
          request.items_per_page || 10,
        );

        return {
          data: {
            rows: data,
          },
          meta: {
            pagination,
          },
        };
      }),
    );
  }

  populateCurrentName(activities: Activity[]): Activity[] {
    return activities?.map((activity) => {
      const translation =
        activity.translations?.find(({ locale }) => locale === 'it') ||
        activity.translations[0];
      return {
        ...activity,
        ...translation,
      };
    });
  }

  deleteActivity(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`api/activities/${id}`);
  }

  addActivity(
    formValue: Omit<Activity, 'activityId' | 'name' | 'description' | 'locale'>,
  ): Observable<Activity> {
    return this.http.post<Activity>(`api/activities`, {
      ...formValue,
    });
  }

  editActivity(
    formValue: Omit<Activity, 'name' | 'description' | 'locale'>,
  ): Observable<Activity> {
    return this.http.put<Activity>(`api/activities/${formValue.activityId}`, {
      ...formValue,
    });
  }
}
