import { createReducer, on, Action } from '@ngrx/store';
import * as Actions from './properties.actions';
import { featureAdapter, initialState, State } from './properties.state';

const reducerFunction = createReducer(
  initialState,
  on(Actions.loadRequest, (state, { disableLoading }) => ({
    ...state,
    loading: !disableLoading,
    error: null,
  })),
  on(Actions.loadSuccess, (state, { ids, properties, pagination }) => {
    const adapterMethod =
      pagination.current_page > 1
        ? featureAdapter.addMany
        : featureAdapter.setAll;

    return adapterMethod(properties, {
      ...state,
      propertiesIDS: ids,
      pagination,
      loading: false,
    });
  }),
  on(Actions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(
    Actions.selectRequest,
    (state, { selectedPropertiesIDS, organizationId }) => ({
      ...state,
      selectedProperties: selectedPropertiesIDS.reduce(
        (selectedProperties, propertyID) => ({
          ...selectedProperties,
          [propertyID]: { ...state.entities[propertyID], id: propertyID },
        }),
        {},
      ),
    }),
  ),
  on(Actions.selectSuccess, (state, { selectedProperties }) => ({
    ...state,
    selectedProperties,
  })),

  on(Actions.reset, () => initialState),
);

export function reducer(state: State | undefined, action: Action) {
  return reducerFunction(state, action);
}
