import { createAction, props } from '@ngrx/store';
import { PropertyTag } from '@booking-booster-client/models';

export const loadRequest = createAction('[Properties Tags] Load Request');
export const loadSuccess = createAction(
  '[Properties Tags] Load Success',
  props<{ propertiesTags: PropertyTag[] }>()
);
export const loadFailure = createAction(
  '[Properties Tags] Load Failure',
  props<{ error: unknown }>()
);

export const reset = createAction('[Properties] Reset');
