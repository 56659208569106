import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  ApiResponse,
  ListResponse,
  LoadRoleTableRequest,
  Paginated,
  Role,
} from '@booking-booster-client/models';
import { paginate } from '../../fake-api-response';
import { DataPermissions } from 'libs/models/src/lib/models/permission.model';
import { generateSearchQuery } from '@booking-booster-client/helpers';

@Injectable({
  providedIn: 'root',
})
export class RoleApiService {
  constructor(private http: HttpClient) {}

  loadRoles(request: LoadRoleTableRequest) {
    return this.http.get<Paginated<Role>>(
      `api/roles${generateSearchQuery(request)}`,
    );
  }

  deleteRole(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`api/roles/${id}`);
  }

  addRole(formValue: Omit<Role, 'roleId'>): Observable<Role> {
    return this.http.post<Role>(`api/roles`, {
      ...formValue,
    });
  }

  editRole(formValue: Role): Observable<Role> {
    return this.http.put<Role>(`api/roles/${formValue.roleId}`, {
      ...formValue,
    });
  }

  loadPermissions(): Observable<DataPermissions> {
    return this.http.get<DataPermissions>(`api/users/me/permissions`);
  }
}
