import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { PrimeNGConfig } from 'primeng/api';
import { authCodeFlowConfig } from './auth.config';
import { Store } from '@ngrx/store';
import { UserStoreActions } from './common-states/user';
import { supportedLanguagesActionGroup } from './root-store/supported-languages';
import { filter, take } from 'rxjs';
import { SearchEnginesStoreActions } from './common-states/search-engines';

@Component({
  selector: 'bb-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  store = inject(Store);

  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private oauthService: OAuthService,
  ) {
    this.translateService.get('primeng').subscribe((res) => {
      this.config.setTranslation(res);
    });

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    //When the token expired the token is refreshed, the token expire after 1 hour
    this.oauthService.setupAutomaticSilentRefresh();

    if (this.oauthService.hasValidAccessToken()) {
      this.loadBasicInfo();
    } else {
      this.oauthService.events
        .pipe(
          filter((event) => event.type === 'token_received'),
          take(1),
        )
        .subscribe(() => {
          this.loadBasicInfo();
        });
    }
  }

  loadBasicInfo() {
    this.store.dispatch(UserStoreActions.loadRequest({}));
    this.store.dispatch(supportedLanguagesActionGroup.loadRequest());
    this.store.dispatch(SearchEnginesStoreActions.loadRequest());
  }
}
