import { isDate } from 'lodash-es';

export const DEFAULT_SERVER_FORMAT = 'yyyy-MM-dd';

export function dateToServerFormat(
  date: Date | any,
  format = DEFAULT_SERVER_FORMAT,
): string {
  if (!isDate(date)) {
    return date;
  }

  return 'DATETODO';
}
