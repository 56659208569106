import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State, featureAdapter } from './channel.state';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_KEY);

export const selectChannelId = createSelector(
  selectFeatureState,
  (state: State) => state?.channelId,
);

export const selectConnection = createSelector(
  selectFeatureState,
  (state: State) => state.connection,
);

export const selectProperties = createSelector(
  selectFeatureState,
  (state: State) => state.accommodations,
);

export const selectChannels =
  featureAdapter.getSelectors(selectFeatureState).selectAll;

export const selectEntity =
  featureAdapter.getSelectors(selectFeatureState).selectEntities;

export const selectChannelById = (id: string) =>
  createSelector(selectEntity, (channels) => {
    return channels?.[id];
  });

export const selectLoading = createSelector(
  selectFeatureState,
  (state: State) => state.loading,
);

export const selectError = createSelector(
  selectFeatureState,
  (state: State) => state.error,
);
