import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_KEY, State, featureAdapter } from './organization.state';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_KEY);

export const selectOrganizationId = createSelector(
  selectFeatureState,
  (state: State) => state?.organizationId,
);

export const selectOrganizations =
  featureAdapter.getSelectors(selectFeatureState).selectAll;

export const selectEntity =
  featureAdapter.getSelectors(selectFeatureState).selectEntities;

export const selectOrganization = createSelector(
  selectEntity,
  selectOrganizationId,
  (organizations, id) => {
    if (!id) {
      return null;
    }
    return organizations?.[id];
  },
);
