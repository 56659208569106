<div class="bb-main-container">
  @if (!addMode && this.organizations.length > 0) {
    <div>
      <div class="bb-sub-container">
        <label for="organization">{{
          'organization' | translate | upperfirst
        }}</label>
        <p-dropdown
          [formControl]="organizationControl"
          [options]="organizations"
          optionLabel="name"
          appendTo="body"
          optionValue="organizationId"
          styleClass="bb-total-width"
          [placeholder]="'organization_insert' | translate | upperfirst"
        />
        @if (organizationControl.invalid && organizationControl.touched) {
          <div>
            {{ 'organization_request' | translate | upperfirst }}
          </div>
        }
      </div>

      <div class="flex justify-end bb-button-save-modal">
        <button
          type="button"
          pButton
          [label]="'save_organization' | translate | upperfirst"
          (click)="selectOrganization()"
        ></button>
      </div>
    </div>
  }

  @if (this.organizations.length <= 0 && !addMode) {
    <div class="flex justify-end bb-button-save-modal">
      <button
        class="bb-mt-20"
        type="button"
        pButton
        (click)="changeInAddMode()"
        [label]="'create_organization' | translate | upperfirst"
      ></button>
    </div>
  }

  @if (addMode) {
    <div>
      <div class="bb-sub-container">
        <label for="organizationName">{{
          'name' | translate | upperfirst
        }}</label>
        <input
          [formControl]="organizationNameControl"
          pInputText
          id="organizationName"
        />

        @if (
          organizationNameControl.invalid && organizationNameControl.touched
        ) {
          <div>
            {{ 'insert_name_organization' | translate | upperfirst }}
          </div>
        }
      </div>

      <div class="flex justify-end bb-button-save-modal">
        <button
          type="button"
          pButton
          (click)="createOrganization()"
          [label]="'save_organization' | translate | upperfirst"
        ></button>
      </div>
    </div>
  }
</div>
