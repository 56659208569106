import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeRu from '@angular/common/locales/ru';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const supportedLanguages: Record<string, { locale: unknown }> = {
  en: { locale: localeEn },
  fr: { locale: localeFr },
  it: { locale: localeIt },
  es: { locale: localeEs },
  ja: { locale: localeJa },
  ru: { locale: localeRu },
  de: { locale: localeDe },
};

Object.entries(supportedLanguages).forEach(([language, { locale }]) =>
  registerLocaleData(locale, language)
);

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class AppLocaleModule {
  constructor(translate: TranslateService) {
    const lang = this.getDefaultLanguage();
    translate.setDefaultLang(lang);
    translate.use(lang);
  }

  private getDefaultLanguage() {
    const defaultLanguage = window.navigator.language?.slice(0, 2);

    if (!defaultLanguage || !supportedLanguages[defaultLanguage]) {
      return 'en';
    }

    return defaultLanguage;
  }
}
