import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { PropertiesApiService } from '@booking-booster-client/api';
import * as fromActions from './properties.actions';
import * as fromState from './properties.state';
import * as fromSelectors from './properties.selectors';
import { Action, select, Store } from '@ngrx/store';

@Injectable()
export class PropertiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromState.State>,
    private propertiesApiService: PropertiesApiService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      withLatestFrom(
        this.store.pipe(select(fromSelectors.selectSelectedProperties)),
      ),
      switchMap(([{ request, organizationId }, selectedProperties]) => {
        return this.propertiesApiService.getIndex(request, organizationId).pipe(
          mergeMap(({ data: { entities, ids, organizationId }, meta }) => {
            const effects: Action[] = [
              fromActions.loadSuccess({
                ids,
                properties: entities,
                pagination: meta.pagination,
              }),
            ];

            if (!selectedProperties) {
              effects.push(
                fromActions.selectRequest({
                  selectedPropertiesIDS: ids,
                  organizationId: organizationId,
                }),
              );
            }

            return effects;
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectRequest),
      filter(({ selectedPropertiesIDS }) => !!selectedPropertiesIDS?.length),
      switchMap(({ selectedPropertiesIDS, organizationId }) =>
        this.propertiesApiService
          .getIndex(
            {
              properties_ids: selectedPropertiesIDS,
            },
            organizationId,
          )
          .pipe(
            map(({ data }) => {
              return fromActions.selectSuccess({
                selectedProperties: data.entities.reduce(
                  (selectedProperties, property) => ({
                    ...selectedProperties,
                    [property.accommodationId]: property,
                  }),
                  {},
                ),
              });
            }),
            catchError((error) => {
              return of(fromActions.selectFailure(error));
            }),
          ),
      ),
    ),
  );
}
