import { User, Nullable } from '@booking-booster-client/models';

export const USER_FEATURE_KEY = 'user';

export interface State {
  user: Nullable<User>;
  error: unknown;
}

export const initialState: State = {
  user: null,
  error: null,
};
