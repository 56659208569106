import { Injectable, QueryList } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Navigable } from '@booking-booster-client/models';
import { filter, map, merge, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigableService {
  constructor(private router: Router) {}

  getActiveIndex(list: QueryList<Navigable>) {
    return merge([
      list.changes.pipe(startWith(list)),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ),
    ]).pipe(
      map(() => {
        return list.toArray().findIndex((navigable) => {
          return this.isActive(navigable);
        });
      })
    );
  }

  getActiveChanges(navigable: Navigable) {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null)
      )
      .pipe(map(() => this.isActive(navigable)));
  }

  isActive({ navigationLink, navigationLinkExactMatch }: Navigable) {
    if (!navigationLink) {
      return false;
    }

    return this.router.isActive(
      typeof navigationLink === 'string'
        ? navigationLink
        : this.router.createUrlTree(navigationLink),
      navigationLinkExactMatch
    );
  }
}
