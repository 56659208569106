import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, Observable, of } from 'rxjs';
import {
  ApiResponse,
  LoadAdsRequest,
  LoadStatisticsTableRequest,
  Property,
  StatisticsTableRow,
} from '@booking-booster-client/models';
import { paginate } from '../../fake-api-response';

import { random, sortBy } from 'lodash';
import { HOTELS } from './properties-api.service';

const FAKE_DATA = [
  {
    views: 5890,
    visits: 3234,
    ctr: 55,
    cpc: 0.88,
    reservations: 488,
    reservation_cost: 21,
    conversion_percentage: 24,
    lost_impression_share_rank: 200,
    lost_impression_share_budget: 1323,
    revenue: 62464,
    total_expense: 2845.92,
    cost_incidence: 5,
    phone_calls: 218,
  },
  {
    views: 6032,
    visits: 4532,
    ctr: 75,
    cpc: 0.52,
    reservations: 1233,
    reservation_cost: 12,
    conversion_percentage: 22,
    lost_impression_share_rank: 2003,
    lost_impression_share_budget: 444,
    revenue: 157824,
    total_expense: 2356.64,
    cost_incidence: 14,
    phone_calls: 3453,
  },
  {
    views: 3245,
    visits: 1255,
    ctr: 39,
    cpc: 0.21,
    reservations: 711,
    reservation_cost: 10,
    conversion_percentage: 33,
    lost_impression_share_rank: 302,
    lost_impression_share_budget: 4353,
    revenue: 91008,
    total_expense: 263.55,
    cost_incidence: 12,
    phone_calls: 4666,
  },
  {
    views: 6321,
    visits: 5432,
    ctr: 86,
    cpc: 0.85,
    reservations: 826,
    reservation_cost: 18,
    conversion_percentage: 12,
    lost_impression_share_rank: 3354,
    lost_impression_share_budget: 234,
    revenue: 105728,
    total_expense: 4617.2,
    cost_incidence: 11,
    phone_calls: 5545,
  },
  {
    views: 423,
    visits: 323,
    ctr: 76,
    cpc: 0.38,
    reservations: 144,
    reservation_cost: 22,
    conversion_percentage: 32,
    lost_impression_share_rank: 221,
    lost_impression_share_budget: 423,
    revenue: 18432,
    total_expense: 122.74,
    cost_incidence: 8,
    phone_calls: 677,
  },
  {
    views: 2345,
    visits: 1675,
    ctr: 71,
    cpc: 0.44,
    reservations: 877,
    reservation_cost: 16,
    conversion_percentage: 32,
    lost_impression_share_rank: 245,
    lost_impression_share_budget: 2345,
    revenue: 112256,
    total_expense: 737,
    cost_incidence: 16,
    phone_calls: 454,
  },
  {
    views: 6236,
    visits: 5445,
    ctr: 87,
    cpc: 0.31,
    reservations: 3100,
    reservation_cost: 18,
    conversion_percentage: 16,
    lost_impression_share_rank: 2355,
    lost_impression_share_budget: 345,
    revenue: 396800,
    total_expense: 1687.95,
    cost_incidence: 12,
    phone_calls: 6667,
  },
  {
    views: 6745,
    visits: 2399,
    ctr: 36,
    cpc: 0.35,
    reservations: 1200,
    reservation_cost: 14,
    conversion_percentage: 42,
    lost_impression_share_rank: 564,
    lost_impression_share_budget: 445,
    revenue: 153600,
    total_expense: 839.65,
    cost_incidence: 8,
    phone_calls: 5677,
  },
  {
    views: 1125,
    visits: 1100,
    ctr: 98,
    cpc: 0.76,
    reservations: 544,
    reservation_cost: 20,
    conversion_percentage: 10,
    lost_impression_share_rank: 345,
    lost_impression_share_budget: 4545,
    revenue: 69632,
    total_expense: 836,
    cost_incidence: 9,
    phone_calls: 325,
  },
  {
    views: 5568,
    visits: 4533,
    ctr: 81,
    cpc: 0.22,
    reservations: 2411,
    reservation_cost: 22,
    conversion_percentage: 22,
    lost_impression_share_rank: 6565,
    lost_impression_share_budget: 4545,
    revenue: 308608,
    total_expense: 997.26,
    cost_incidence: 11,
    phone_calls: 6667,
  },
  {
    views: 4566,
    visits: 3422,
    ctr: 75,
    cpc: 0.45,
    reservations: 2188,
    reservation_cost: 11,
    conversion_percentage: 9,
    lost_impression_share_rank: 43453,
    lost_impression_share_budget: 3453,
    revenue: 280064,
    total_expense: 1539.9,
    cost_incidence: 8,
    phone_calls: 6577,
  },
];

const FAKE_DATA_2 = [
  {
    property: HOTELS[1],
    ads: [
      {
        name: 'Annuncio 1',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Cavagrande - Sito Ufficiale',
              description:
                'Prenota il tuo soggiorno sul sito ufficiale al Miglior prezzo Garantito.',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Jova Beach',
              'Chiesa Madre Acireale Hotel',
              'Hotel economico Acireale',
              'Hotel Centro Storico Chiesa Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Hotel Cavagrande - Offizielle Seite',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Historisches Zentrum',
              'Hotel Acireale Jova Strand',
              'Hotel Chiesa Madre Acireale',
              'Billiges Hotel Acireale',
              'Hotel Historisches Zentrum Kirche von Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Hotel Cavagrande - Sitio Oficial',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Histórico',
              'Hotel Acireale Jova Beach',
              'Hotel Chiesa Madre Acireale',
              'Hotel barato Acireale',
              'Hotel Centro Histórico Acireale Iglesia',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Hôtel Cavagrande - Site Officiel',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hôtel Acireale Centre Historique',
              'Hôtel Acireale Jova Beach',
              'Hôtel Chiesa Madre Acireale',
              'Hôtel pas cher Acireale',
              'Hôtel Centre Historique Église Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Hotel Cavagrande - Official Site',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Historical Center',
              'Hotel Acireale Jova Beach',
              'Hotel Chiesa Madre Acireale',
              'Cheap Hotel Acireale',
              'Hotel Historical Center Acireale Church',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Отель Кавагранде - Официальный сайт',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Отель Ачиреале Исторический Центр',
              'Отель Ачиреале Джова Бич',
              'Отель Кьеза Мадре Ачиреале',
              'Дешёвый отель Ачиреале',
              'Отель Исторический центр Церкви Ачиреале',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'HotelCavagrande-公式サイト',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'ホテルアチレアーレ歴史センター',
              'ホテルアチレアーレジョバビーチ',
              'Hotel Chiesa Madre Acireale',
              '格安ホテルアチレアーレ',
              'ホテルヒストリカルセンターアチレアーレ教会',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 2',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Cavagrande Acireale',
              description:
                "L'albergo a si trova in una location perfetta per visitare Acireale. A pochi passi dalla Chiesa Madre, dalla Playa e dalla Stazione Ferroviaria",

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Cavagrande Acireale',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Cavagrande Acireale-Hotels',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Dormire Acireale a prezzi bassi',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Hoteles en Cavagrande Acireale',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Chiesa Madre Acireale Hotel',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Hôtels Cavagrande Acireale',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Cavagrande Acireale hotels',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Отели Кавагранде-Ачиреале',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'CavagrandeAcirealeホテル',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 3',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Acireale a partire da € 128',
              description:
                'Hotel Cavagrande si trova in una zona strategica di Acireale. A pochi passi dalla Chiesa Madre, dalla Playa e dalla Stazione Ferroviaria',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 4',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Centro Storico di Acireale',
              description:
                'Ideale per un soggiorno vicino alle grandi bellezze di Acireale.',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 5',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Centro Storico di Acireale',
              description:
                'Visita Acireale partendo da suo centro e goditi i comfort delle nostre camere.',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 6',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Cavagrande a 50 mt da Stazione Ferroviaria',
              description:
                'Hotel in zona Stazione Ferroviaria di Acireale a partire da € 88.',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 7',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel a 40 mt da Chiesa Madre',
              description:
                'Hotel Cavagrande a 12 min da Chiesa Madre di Acireale',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 8',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Hotel Jova Beach Party 2022',
              description:
                'Prenota il tuo soggiorno a partire da € 88. Offerta valida fino al 12 Agosto',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 9',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Cerchi un Hotel ad Acireale?',
              description:
                'Scegli le nostre Camere con Vista Mare, Vista Etna e Wifi Gratuito',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 10',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title:
                '{KeyWord: Hotel Cavagrande} | Miglior Tariffa Disponibile',
              description:
                'Prenota il tuo soggiorno a partire da € 88. Offerta valida fino al 12 Agosto',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 11',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title:
                '{KeyWord: Hotel Cavagrande Acireale} | Miglior Tariffa Garantita',
              description:
                'A pochi passi dalla Chiesa Madre, dalla Playa e dalla Stazione Ferroviaria',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Hotel Acireale Centro Storico',
              'Hotel Acireale Stazione Ferroviaria',
              'Hotel Acireale Jova Beach',
              'Acireale Hotel Offetre',
              'Hotel Cavagrande Acireale',
              'Chiesa Madre Acireale Hotel',
              'Dormire Acireale a prezzi bassi',
              'Hotel economico Acireale',
              'Hotel mare Acireale',
              'Hotel Centro Storico Chiesa Acireale',
              'Hotel vicino al Mare Acireale',
            ],
          },
        ],
      },
    ],
  },
  {
    property: HOTELS[2],
    ads: [
      {
        name: 'Annuncio 1',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Flora Apartment - Sito Ufficiale',
              description:
                'Prenota il tuo soggiorno sul sito ufficiale al Miglior prezzo Garantito.',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Appartamento Catania Aeroporto',
              'Appartamento Catania Centro Storico',
              'Flora Apartment Catania',
              'Appartamenti economico Catania',
              'Appartamento vicino al Mare Catania',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Flora Apartment - Offizielle Seite',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Apartment am Flughafen Catania',
              'Wohnung Catania Altstadt',
              'Flora Catania Wohnung',
              'Billige Wohnungen Catania',
              'Wohnung am Meer Catania',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Apartamento Flora - Sitio Oficial',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Apartamento en el aeropuerto de Catania',
              'Apartamento Catania Centro Histórico',
              'Apartamento Flora Catania',
              'Apartamentos baratos Catania',
              'Apartamento cerca del mar Catania',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Appartement Flora - Site Officiel',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "Appartement de l'aéroport de Catane",
              'Appartement Catane Centre Historique',
              'Appartement Flora Catane',
              'Appartements pas chers Catane',
              'Appartement près de la mer Catane',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Flora Apartment - Official Site',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Catania Airport Apartment',
              'Apartment Catania Historical Center',
              'Flora Catania Apartment',
              'Cheap apartments Catania',
              'Apartment near the sea Catania',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Квартира Флора - Официальный сайт',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Апартаменты в аэропорту Катании',
              'Апартаменты Исторический Центр Катании',
              'Апартаменты Флора Катания',
              'Недорогие апартаменты Катания',
              'Квартира у моря Катания',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'フローラアパートメント-公式サイト',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'カターニア空港アパート',
              'アパートカターニア歴史センター',
              'フローラカタニアアパートメント',
              '安いアパートカターニア',
              'カターニア海の近くのアパート',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 2',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Flora Apartment Catania',
              description:
                "L'appartamento a si trova in una location perfetta per visitare Catania. A pochi passi dall'Aeroporto, dalla Playa e dalla Cattedrale di Sant'Agata",

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Appartamento Catania Aeroporto',
              'Appartamento Catania Centro Storico',
              'Flora Apartment Catania',
              'Appartamenti economico Catania',
              'Appartamento vicino al Mare Catania',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Flora Wohnung Catania',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Apartment am Flughafen Catania',
              'Wohnung Catania Altstadt',
              'Flora Catania Wohnung',
              'Billige Wohnungen Catania',
              'Wohnung am Meer Catania',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Flora Apartamento Catania',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Apartamento en el aeropuerto de Catania',
              'Apartamento Catania Centro Histórico',
              'Apartamento Flora Catania',
              'Apartamentos baratos Catania',
              'Apartamento cerca del mar Catania',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Appartement Flora Catane',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "Appartement de l'aéroport de Catane",
              'Appartement Catane Centre Historique',
              'Appartement Flora Catane',
              'Appartements pas chers Catane',
              'Appartement près de la mer Catane',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Flora Apartment Catania',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Catania Airport Apartment',
              'Apartment Catania Historical Center',
              'Flora Catania Apartment',
              'Cheap apartments Catania',
              'Apartment near the sea Catania',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Апартаменты Flora Катания',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Апартаменты в аэропорту Катании',
              'Апартаменты Исторический Центр Катании',
              'Апартаменты Флора Катания',
              'Недорогие апартаменты Катания',
              'Квартира у моря Катания',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'フローラアパートメントカターニア',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'カターニア空港アパート',
              'アパートカターニア歴史センター',
              'フローラカタニアアパートメント',
              '安いアパートカターニア',
              'カターニア海の近くのアパート',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 3',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Appartamento Catania a partire da € 68',
              description:
                "Flora Apartment  si trova in una zona strategica di Catania. A pochi passi dall'Aeroporto, dalla Playa e dalla Cattedrale di Sant'Agata",

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 4',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Appartamento Centro Storico di Catania',
              description:
                'Ideale per un soggiorno vicino alle grandi bellezze di Catania.',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 5',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Appartamento Centro Storico di Catania',
              description:
                'Visita Catania partendo da suo centro e goditi i comfort delle nostre camere.',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 6',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Flora Apartment a 50 mt da Stazione Ferroviaria',
              description:
                'Appartamento in zona Stazione Ferroviaria di Catania a partire da € 88. ',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 7',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Appartamento a 40 mt da Aeroporto Vincenzo Bellini',
              description:
                "Flora Apartment a 12 min dalla Cattedrale di Sant'Agata",
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 8',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Cerchi un Appartamento a Catania?',
              description:
                'Scegli le nostre Camere con Vista Mare, Vista Etna e Wifi Gratuito',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 9',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Cerchi un Appartamento a Catania?',
              description:
                'Scegli le nostre Camere con Vista Mare, Vista Etna e Wifi Gratuito',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 10',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title:
                '{KeyWord: Flora Apartment}  | Miglior Tariffa Disponibile',
              description:
                'Prenota il tuo soggiorno a partire da € 88. Offerta valida fino al 12 Agosto',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 11',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title:
                '{KeyWord:  Flora Apartment Catania} | Miglior Tariffa Garantita',
              description:
                "A pochi passi dalla Cattedrale di Sant'Agata, dalla Playa e dalla Stazione Ferroviaria",
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
    ],
  },
  {
    property: HOTELS[3],
    ads: [
      {
        name: 'Annuncio 1',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Corte dei Sari - Sito Ufficiale',
              description:
                'Prenota il tuo soggiorno sul sito ufficiale al Miglior prezzo Garantito.',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania vicino Basilica Sant'Agata",
              'Dormire Catania a prezzi bassi',
              'B&B mare Catania',
              'B&B Centro Storico Aeroporto Catania',
              'B&B vicino al Mare Catania',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Corte dei Sari - Offizielle Seite',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania in der Nähe der Basilika Sant'Agata",
              'Schlafen in Catania zu günstigen Preisen',
              'B&B am Meer von Catania',
              'B&B Historisches Zentrum Flughafen Catania',
              'B&B am Meer Catania',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Corte dei Sari - Sitio Oficial',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania cerca de la Basílica Sant'Agata",
              'Dormir en Catania a precios bajos',
              'B&B junto al mar Catania',
              'B&B Centro Histórico Catania Aeropuerto',
              'B&B cerca del mar Catania',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Corte dei Sari - Site officiel',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catane près de la Basilique Sant'Agata",
              'Dormir à Catane à petits prix',
              'B&B bord de mer Catane',
              "B&B Centre historique de l'aéroport de Catane",
              'B&B près de la mer Catane',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Corte dei Sari - Official Site',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania near Basilica Sant'Agata",
              'Sleep in Catania at low prices',
              'B&B seaside Catania',
              'B&B Historical Center Catania Airport',
              'B&B near the sea Catania',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Корте деи Сари - Официальный сайт',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'B&B Catania возле базилики Сант-Агата',
              'Отдохнуть в Катании по низким ценам',
              'B&B приморский Катания',
              'B&B Исторический центр аэропорта Катании',
              'B&B у моря Катания',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'CortedeiSari-公式サイト',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'バシリカサンタガタ近くのB＆Bカターニア',
              'カターニアで低価格で眠る',
              'B＆Bシーサイドカターニア',
              'B＆Bヒストリカルセンターカターニア空港',
              'カターニア海の近くのB＆B',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 2',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'B&B Catania a partire da € 68',
              description:
                "Il B&B si trova in una location perfetta per visitare Catania. A pochi passi dall'Aeroporto, dalla Playa e dalla Cattedrale di Sant'Agata",
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania vicino Basilica Sant'Agata",
              'Dormire Catania a prezzi bassi',
              'B&B mare Catania',
              'B&B Centro Storico Aeroporto Catania',
              'B&B vicino al Mare Catania',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'B&B Catania ab 68 €',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania in der Nähe der Basilika Sant'Agata",
              'Schlafen in Catania zu günstigen Preisen',
              'B&B am Meer von Catania',
              'B&B Historisches Zentrum Flughafen Catania',
              'B&B am Meer Catania',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'B&B Catania desde € 68',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania cerca de la Basílica Sant'Agata",
              'Dormir en Catania a precios bajos',
              'B&B junto al mar Catania',
              'B&B Centro Histórico Catania Aeropuerto',
              'B&B cerca del mar Catania',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'B&B Catane à partir de 68 €',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catane près de la Basilique Sant'Agata",
              'Dormir à Catane à petits prix',
              'B&B bord de mer Catane',
              "B&B Centre historique de l'aéroport de Catane",
              'B&B près de la mer Catane',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'B&B Catania starting from € 68',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              "B&B Catania near Basilica Sant'Agata",
              'Sleep in Catania at low prices',
              'B&B seaside Catania',
              'B&B Historical Center Catania Airport',
              'B&B near the sea Catania',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'B&B Catania от € 68',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'B&B Catania возле базилики Сант-Агата',
              'Отдохнуть в Катании по низким ценам',
              'B&B приморский Катания',
              'B&B Исторический центр аэропорта Катании',
              'B&B у моря Катания',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'B＆Bカターニア€68から',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'バシリカサンタガタ近くのB＆Bカターニア',
              'カターニアで低価格で眠る',
              'B＆Bシーサイドカターニア',
              'B＆Bヒストリカルセンターカターニア空港',
              'カターニア海の近くのB＆B',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 3',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'B&B Catania a partire da € 68',
              description:
                "Corte dei Sari  si trova in una zona strategica di Catania. A pochi passi dall'Aeroporto, dalla Playa e dalla Cattedrale di Sant'Agata",
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 4',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'B&B Centro Storico di Catania',
              description:
                'Ideale per un soggiorno vicino alle grandi bellezze di Catania.',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 5',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'B&B Centro Storico di Catania',
              description:
                'Visita Catania partendo da suo centro e goditi i comfort delle nostre camere.',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 6',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Corte dei Sari a 50 mt da Stazione Ferroviaria',
              description:
                'B&B in zona Stazione Ferroviaria di Catania a partire da € 88. ',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 7',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'B&B  a 40 mt da Aeroporto Vincenzo Bellini',
              description:
                "Corte dei Sari a 12 min dalla Cattedrale di Sant'Agata",
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 8',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'B&B Jova Beach Party 2022',
              description:
                'Prenota il tuo soggiorno a partire da € 88. Offerta valida fino al 12 Agosto',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 9',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: 'Cerchi un B&B a Catania?',
              description:
                'Scegli le nostre Camere con Vista Mare, Vista Etna e Wifi Gratuito',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 10',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title: '{KeyWord: Corte dei Sari}  | Miglior Tariffa Disponibile',
              description:
                'Prenota il tuo soggiorno a partire da € 88. Offerta valida fino al 12 Agosto',
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
      {
        name: 'Annuncio 11',
        phone_call_extension: '+39 0957167027',
        link_extension: '/appartamenti-centro-storico /casa-vacanza-centro',
        translations: [
          {
            locale: 'it',
            ad: {
              title:
                '{KeyWord:  Corte dei Sari Catania} | Miglior Tariffa Garantita',
              description:
                "A pochi passi dalla Cattedrale di Sant'Agata, dalla Playa e dalla Stazione Ferroviaria",
              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'de',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'es',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'fr',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'gb',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'ru',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
          {
            locale: 'jp',
            ad: {
              title: 'Lorem Ipsum Apartment, Tariffa Garantita',
              description:
                'Questa è la descrizione che ci aiuterà a ricevere tante prenotazioni dirette',

              web_site: 'www.loremipsumapartment.com',
            },
            keywords: [
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              'Aliquam fringilla sed quam posuere maximus',
              'Pellentesque tempor neque a felis',
              'eleifend placerat',
              'Duis vulputate sit amet sem in tincidunt',
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: 'root',
})
export class AdsApiService {
  constructor(private httpClient: HttpClient) {}

  // [GET] properties/ads
  load(request: LoadAdsRequest) {
    const records = FAKE_DATA_2.filter((record) => {
      let valid = true;

      if (request.properties_ids) {
        valid = valid && request.properties_ids.includes(record.property.id);
      }

      if (request.search) {
        const searchString =
          `${record.property.name}@@${record.property.address}`.toLowerCase();

        valid = valid && searchString.includes(request.search.toLowerCase());
      }

      return valid;
    });

    const { data, pagination } = paginate(records, 1, 10);

    return of({
      data,
      meta: {
        pagination,
      },
    }).pipe(delay(300));
  }

  // [GET] properties/ads/statistics
  loadStatistics(request: LoadStatisticsTableRequest): Observable<
    ApiResponse<{
      rows: StatisticsTableRow[];
      totals: Partial<StatisticsTableRow>;
    }>
  > {
    const records: StatisticsTableRow[] = [];

    const pushRecord = (
      id: number,
      name: string,
      locale: string,
      property: Property
    ) =>
      records.push({
        id,
        name,
        locale,
        paused: false,
        property,
        ...FAKE_DATA[random(0, FAKE_DATA.length - 1)],
      });

    FAKE_DATA_2.forEach((row, index1) => {
      row.ads.forEach((ad, index2) => {
        ad.translations.forEach((translation, index3) => {
          if (request.type === 'keywords') {
            translation.keywords.forEach((keyword, index4) => {
              pushRecord(
                index1 + index2 + index3 + index4 + 1,
                keyword,
                translation.locale,
                row.property
              );
            });
          } else {
            pushRecord(
              index1 + index2 + index3 + 1,
              translation.ad.title,
              translation.locale,
              row.property
            );
          }
        });
      });
    });

    let results = records.filter((row) => {
      let valid = true;

      if (request.filter) {
        valid =
          valid &&
          row.name.toLowerCase().includes(request.filter.toLowerCase());
      }

      if (request.properties_ids) {
        valid = valid && request.properties_ids.includes(row.property.id);
      }

      if (request.locales) {
        valid = valid && request.locales.includes(row.locale);
      }

      return valid;
    });

    if (request.sort) {
      const [key, strategy] = request.sort.split(':');
      results = sortBy(results, key);

      if (strategy === 'DESC') {
        results = results.reverse();
      }
    }

    const totals = {
      cpc: 0.36,
      ctr: 69,
      views: 48496,
      visits: 33350,
      revenue: 1756416,
      phone_calls: 86126,
      reservations: 13722,
      total_expense: 16843.81,
      cost_incidence: 9,
      reservation_cost: 16.73,
      conversion_percentage: 23,
      lost_impression_share_budget: 22455,
      lost_impression_share_rank: 59607,
    };

    const { data, pagination } = paginate(
      results,
      request.page || 1,
      request.items_per_page || 10
    );

    return of({
      data: {
        totals,
        rows: data,
      },
      meta: {
        pagination,
      },
    }).pipe(delay(300));
  }
}
