import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, of } from 'rxjs';
import {
  Event,
  EventCategory,
  LoadEventsRequest,
} from '@booking-booster-client/models';

const EVENTS_CATEGORIES: EventCategory[] = [
  {
    id: 1,
    name: 'Concerto',
    icon: 'fa-music',
    color: '#eea200',
  },
  {
    id: 2,
    name: 'Fiera',
    icon: 'fa-store',
    color: '#2ea92b',
  },
  {
    id: 3,
    name: 'Sport',
    icon: 'fa-futbol',
    color: '#2eacb7',
  },
  {
    id: 4,
    name: 'Congresso',
    icon: 'fa-users',
    color: '#c94141',
  },
];

const getCategory = (categoryID: number) => {
  return EVENTS_CATEGORIES.find(({ id }) => categoryID === id) as EventCategory;
};

const EVENTS: Event[] = [
  {
    id: 1,
    name: 'Jova Beach Party',
    category: getCategory(1),
    date_from: '2022-08-12',
    date_to: '2022-08-13',
  },
  {
    id: 2,
    name: 'Settimana della Sposa',
    category: getCategory(2),
    date_from: '2022-08-12',
    date_to: '2022-08-13',
  },
  {
    id: 3,
    name: 'Congresso Ingegneri Italiani',
    category: getCategory(4),
    date_from: '2022-08-12',
    date_to: '2022-08-13',
  },
  {
    id: 4,
    name: 'Juve - Inter',
    category: getCategory(3),
    date_from: '2022-08-12',
    date_to: '2022-08-13',
  },
];

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  constructor(private httpClient: HttpClient) {}

  // [GET] events
  getIndex(request: LoadEventsRequest) {
    const data = EVENTS.filter((event) => {
      if (!request.search) {
        return true;
      }

      return event.name.toLowerCase().includes(request.search.toLowerCase());
    });

    return of({
      data,
    }).pipe(delay(500));
  }

  // [GET] events/categories
  getTypes() {
    return of({
      data: EVENTS_CATEGORIES,
    }).pipe(delay(500));
  }
}
