import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppLocaleModule } from './app-locale.module';
import { CommonStatesModule } from './common-states/common-states.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppHttpInterceptor } from './interceptors/app-http-interceptor.interceptor';
import { DialogService } from 'primeng/dynamicdialog';
import { RootStoreModule } from './root-store/root-store.module';
import { AuthGuard } from './guards';
import { TruncateCharPipe } from './pipes/truncate-char.pipe';
import { UpperFirstPipe } from '@z-trippete/angular-pipes';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    ToastModule,
    BrowserModule,
    AppLocaleModule,
    RootStoreModule,
    AppRoutingModule,
    CommonStatesModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule,
    TruncateCharPipe,
    OAuthModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    MessageService,
    AuthGuard,
    DialogService,
    UpperFirstPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideStoreDevtools({
      name: 'booking-booster',
      maxAge: 30,
      trace: true,
      connectInZone: true,
    }),
  ],
})
export class AppModule {}
