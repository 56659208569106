import { MonoTypeOperatorFunction, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EffectHooks } from '@booking-booster-client/models';

export function effectHooks<T>(
  hooks?: EffectHooks
): MonoTypeOperatorFunction<T> {
  return pipe(
    tap((data) => hooks?.onSuccess && hooks.onSuccess(data)),
    catchError((error) => {
      if (hooks?.onFailure) {
        hooks.onFailure(error);
      }

      return throwError(error);
    })
  );
}
