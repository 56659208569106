import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatisticsApiService {
  constructor(private httpClient: HttpClient) {}

  // [GET] statistics/revenue/chart
  loadRevenueChart(request: unknown) {
    return of({
      data: [],
    }).pipe(delay(500));
  }
}
