import { Injectable } from '@angular/core';
import { MessageSeverity } from '@booking-booster-client/helpers';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class HandlerMessageService {
  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  showMessageSuccess(text: string, params: Record<string, string> = {}) {
    this.messageService.add({
      severity: MessageSeverity.Success,
      summary: upperFirst(
        this.translate.instant(text, this.processParams(params)),
      ),
    });
  }

  showMessageError(text: string, params: Record<string, string> = {}) {
    this.messageService.add({
      severity: MessageSeverity.Error,
      summary: upperFirst(
        this.translate.instant(text, this.processParams(params)),
      ),
    });
  }

  processParams(params: Record<string, string>) {
    const translatedParams: Record<string, string> = {};
    for (const [key, value] of Object.entries(params)) {
      translatedParams[key] = this.translate.instant(value);
    }
    return translatedParams;
  }
}
