import { createReducer, on, Action } from '@ngrx/store';

import { initialState, State } from './channel.state';
import { channelActionsGroup } from './channel.actions';
import * as fromState from './channel.state';

const channelsReducer = createReducer(
  initialState,

  on(channelActionsGroup.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(channelActionsGroup.loadSuccess, (state, { dataChannels }) =>
    fromState.featureAdapter.setAll(dataChannels.channels, {
      ...state,
      loading: false,
    }),
  ),
  on(channelActionsGroup.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(channelActionsGroup.connectionRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(channelActionsGroup.connectionSuccess, (state, { currentConnection }) => ({
    ...state,
    connection: currentConnection,
    loading: false,
    error: null,
  })),
  on(channelActionsGroup.connectionFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(channelActionsGroup.getAccommodationsRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    channelActionsGroup.getAccommodationsSuccess,
    (state, { accommodations }) => ({
      ...state,
      accommodations,
      loading: false,
      error: null,
    }),
  ),
  on(channelActionsGroup.getAccommodationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(channelActionsGroup.setIdRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(channelActionsGroup.setIdSuccess, (state, { channelId }) => ({
    ...state,
    channelId,
    loading: false,
  })),
  on(channelActionsGroup.setIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  //TODO: This store doesn't have a state for accommodations
  on(channelActionsGroup.addAccommodationsRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(channelActionsGroup.addAccommodationsSuccess, (state, {}) => ({
    ...state,
    loading: false,
  })),
  on(channelActionsGroup.addAccommodationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return channelsReducer(state, action);
}
