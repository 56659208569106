import { ApiResponsePagination } from '@booking-booster-client/models';

export function paginate<T>(data: T[], page = 1, itemsPerPage = 25) {
  const pagination = getPagination(data.length, page, itemsPerPage);
  return { data: data.slice(pagination.from - 1, pagination.to + 1), pagination };
}

export function getPagination(
  total: number,
  page = 1,
  itemsPerPage = 25
): ApiResponsePagination {
  return {
    current_page: page,
    from: itemsPerPage * (page - 1) + 1,
    to: itemsPerPage * page,
    last_page: Math.ceil(total / itemsPerPage),
    per_page: itemsPerPage,
    total,
  };
}
