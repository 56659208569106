import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateChar',
  standalone: true,
})
export class TruncateCharPipe implements PipeTransform {
  transform(value: string | undefined, charlimit: number = 100): string {
    if (value) {
      if (!value || value.length <= charlimit) {
        return value;
      }

      const withoutHtml = value.replace(/<(?:.|\n)*?>/gm, '');
      const shortened = withoutHtml.substring(0, charlimit) + '...';
      return shortened;
    }
    return '';
  }
}
