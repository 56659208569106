import {
  ApiResponsePagination,
  Nullable,
  Property,
} from '@booking-booster-client/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const USER_FEATURE_KEY = 'properties';

export const featureAdapter = createEntityAdapter<Property>({
  selectId: (model) => model.accommodationId,
});

export interface State extends EntityState<Property> {
  selectedProperties: Nullable<Record<number, Partial<Property>>>;
  pagination: Nullable<ApiResponsePagination>;
  propertiesIDS: string[];
  loading: boolean;
  error: unknown;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedProperties: null,
  propertiesIDS: [],
  pagination: null,
  loading: false,
  error: null,
});
