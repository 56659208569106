import { createAction, props } from '@ngrx/store';
import {
  ApiResponsePagination,
  LoadUserPropertiesRequest,
  Property,
} from '@booking-booster-client/models';

export const loadRequest = createAction(
  '[Properties] Load Request',
  props<{
    request: LoadUserPropertiesRequest;
    disableLoading: boolean;
    organizationId?: string;
  }>(),
);
export const loadSuccess = createAction(
  '[Properties] Load Success',
  props<{
    ids: string[];
    properties: Property[];
    pagination: ApiResponsePagination;
  }>(),
);
export const loadFailure = createAction(
  '[Properties] Load Failure',
  props<{ error: unknown }>(),
);

export const selectRequest = createAction(
  '[Properties] Select Request',
  props<{ selectedPropertiesIDS: string[]; organizationId?: string }>(),
);
export const selectSuccess = createAction(
  '[Properties] Select Success',
  props<{ selectedProperties: Record<string, Partial<Property>> }>(),
);
export const selectFailure = createAction(
  '[Properties] Select Failure',
  props<{ error: unknown }>(),
);

export const reset = createAction('[Properties] Reset');
