import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { select, Store } from '@ngrx/store';
import {
  OrganizationSelectors,
  OrganizationStore,
} from '../root-store/organization-state';
import { Nullable } from '@booking-booster-client/models';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor, OnDestroy {
  constructor(
    private oAuthService: OAuthService,
    private store: Store<OrganizationStore.State>,
  ) {
    this.subscription.add(
      this.selectedOrganization$.subscribe((selectOrganization) => {
        this.organization = selectOrganization;
      }),
    );
  }

  selectedOrganization$ = this.store.pipe(
    select(OrganizationSelectors.selectOrganizationId),
  );

  organization: Nullable<string> = null;

  subscription = new Subscription();

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('http://') || request.url.includes('https://')) {
      return next.handle(request);
    }

    if (request.url.includes('api/')) {
      request = request.clone({
        url: environment.host + request.url,
      });
    }

    let headers = request.headers;

    if (this.oAuthService.hasValidIdToken()) {
      headers = request.headers.set(
        'Authorization',
        `Bearer ${this.oAuthService.getIdToken()}`,
      );

      if (this.organization) {
        headers = headers.set('zbb-organization-id', this.organization);
      }
    }

    request = request.clone({ headers });

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
