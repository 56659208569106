import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  ApiResponse,
  ListResponse,
  LoadRoleTableRequest,
  Role,
} from '@booking-booster-client/models';
import { paginate } from '../../fake-api-response';
import { DataPermissions } from 'libs/models/src/lib/models/permission.model';

@Injectable({
  providedIn: 'root',
})
export class RoleApiService {
  constructor(private http: HttpClient) {}

  loadRoles(request: LoadRoleTableRequest): Observable<
    ApiResponse<{
      rows: Role[];
    }>
  > {
    return this.http.get<ListResponse<Role>>(`api/roles`).pipe(
      map((records) => {
        const { data, pagination } = paginate(
          records.items,
          request.page || 1,
          request.items_per_page || 10,
        );

        return {
          data: {
            rows: data,
          },
          meta: {
            pagination,
          },
        };
      }),
    );
  }

  deleteRole(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`api/roles/${id}`);
  }

  addRole(formValue: Omit<Role, 'roleId'>): Observable<Role> {
    return this.http.post<Role>(`api/roles`, {
      ...formValue,
    });
  }

  editRole(formValue: Role): Observable<Role> {
    return this.http.put<Role>(`api/roles/${formValue.roleId}`, {
      ...formValue,
    });
  }

  loadPermissions(): Observable<DataPermissions> {
    return this.http.get<DataPermissions>(`api/permissions`);
  }
}
