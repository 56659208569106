import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PropertiesApiService } from '@booking-booster-client/api';
import * as fromActions from './properties-tags.actions';

@Injectable()
export class PropertiesTagsEffects {
  constructor(
    private actions$: Actions,
    private propertiesApiService: PropertiesApiService
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() =>
        this.propertiesApiService.getTags().pipe(
          map(({ data }) => {
            return fromActions.loadSuccess({
              propertiesTags: data,
            });
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          })
        )
      )
    )
  );
}
