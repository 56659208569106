import { Organization } from '@booking-booster-client/models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Nullable } from 'primeng/ts-helpers';

export const FEATURE_KEY = 'organization';

export const featureAdapter: EntityAdapter<Organization> =
  createEntityAdapter<Organization>({
    selectId: (model) => model.organizationId,
  });

export interface State extends EntityState<Organization> {
  organizationId: Nullable<string>;
}

export const initialState: State = featureAdapter.getInitialState({
  organizationId: null,
});
