/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Nullable } from '@booking-booster-client/models';
import { google } from 'google-maps';

interface ConstructorParams {
  title: string;
  color: string;
  circle?: boolean;
  maskImage?: string;
  borderColor?: string;
  backgroundImage?: string;
  coords: google.maps.LatLng;
}

export class CustomizedImageMarker extends google.maps.OverlayView {
  private div?: HTMLElement;

  bounds: Nullable<google.maps.LatLngBounds>;

  constructor(private params: ConstructorParams) {
    super();
  }

  override onAdd() {
    this.div = this.getDiv();
    const panes = this.getPanes()!;
    panes.overlayLayer.appendChild(this.div);
  }

  override draw() {
    if (!this.params.coords || !this.div) {
      return;
    }

    const overlayProjection = this.getProjection();

    const point = overlayProjection.fromLatLngToDivPixel(this.params.coords)!;

    // Resize the image's div to fit the indicated dimensions.
    this.div.style.left = point.x + 'px';
    this.div.style.top = point.y + 'px';
  }

  override onRemove() {
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
      delete this.div;
    }
  }

  getDraggable() {
    return null;
  }

  private getDiv() {
    const div = document.createElement('div');

    div.style.position = 'absolute';
    div.style.height = '30px';
    div.style.width = '30px';
    div.style.transform = 'translate(-50%, -50%)';

    if (this.params.borderColor) {
      div.style.border = '2px solid';
      div.style.borderColor = this.params.borderColor;
    }

    if (this.params.circle) {
      div.style.borderRadius = '50%';
    }

    if (this.params.backgroundImage) {
      div.style.backgroundImage = `url(${this.params.backgroundImage})`;
      div.style.backgroundSize = 'cover';
      div.style.backgroundOrigin = 'center';
    }

    if (this.params.maskImage) {
      const icon = document.createElement('i');
      icon.classList.add('bb-mask-icon');

      icon.style.setProperty('--icon-image', `url(${this.params.maskImage}`);
      icon.style.setProperty('--icon-color', this.params.color);
      icon.style.position = 'absolute';
      icon.style.bottom = '0';
      icon.style.left = '0';

      div.appendChild(icon);
    }

    if (this.params.title) {
      const title = document.createElement('span');
      title.innerText = this.params.title;

      title.style.color = this.params.color;
      title.style.position = 'absolute';
      title.style.bottom = '-5px';
      title.style.whiteSpace = 'no-wrap';
      title.style.transform = 'translateY(100%)';

      div.appendChild(title);
    }

    return div;
  }
}
