import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY, UserState } from './user.state';

export const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectUser = createSelector(
  getUserState,
  (state: UserState) => state.user,
);

export const selectError = createSelector(
  getUserState,
  (state: UserState) => state.error,
);
