import { Injectable } from '@angular/core';
import { delay, map, of } from 'rxjs';
import {
  LoadPropertiesStatisticsRequest,
  LoadUserPropertiesRequest,
  Property,
} from '@booking-booster-client/models';
import { intersection } from 'lodash';
import { paginate } from '../../fake-api-response';
import { AccomodationApiService } from './accomodations-api.service';

export const HOTELS: Record<number, Property> = {
  1: {
    accommodationId: '',
    id: 1,
    name: 'Hotel Cavagrande',
    logo: 'https://booster.beddy.dev/assets/tmp/hotel1.jpeg',
    currency_symbol: '€',
    address: 'Via Cavagrande 1',
    country: 'Italia',
    state: 'Sicilia',
    county: 'Catania',
    city: 'Acireale',
    zip_code: '95024',
    latitude: 37.6111331,
    longitude: 15.1674326,
    phone: '+39123456789',
    email: 'info@miohotel.dev',
    facebook: 'pagina-facebook',
    instagram: '#paginafacebook',
    tags_ids: [1, 2],
    pois: [],
    events_ids: [1, 2],
    services_ids: [],
    messages: [
      'Abbiamo alcuni suggerimenti per migliorare il tuo sito internet. Attualmente hai un punteggio di 50/100, effettua un <u>nuovo test</u>',
    ],
  },
  2: {
    accommodationId: '',
    id: 2,
    name: 'Flora Apartment',
    logo: 'https://booster.beddy.dev/assets/tmp/hotel2.jpeg',
    currency_symbol: '€',
    address: 'Via dei Fiori 15',
    country: 'Italia',
    state: 'Sicilia',
    county: 'Catania',
    city: 'Acireale',
    zip_code: '95024',
    latitude: 37.6111331,
    longitude: 15.1674326,
    phone: '+39123456789',
    email: 'info@miohotel.dev',
    facebook: 'pagina-facebook',
    instagram: '#paginafacebook',
    tags_ids: [1],
    pois: [],
    events_ids: [1],
    services_ids: [],
    messages: [
      'Abbiamo rilevato che la Germania è uno dei tuoi mercati più performanti, ma il tuo booking engine non risulta in tedesco, ti suggeriamo di intervenire',
    ],
  },
  3: {
    accommodationId: '',
    id: 3,
    name: 'Corte dei Sari',
    logo: 'https://booster.beddy.dev/assets/tmp/hotel3.jpeg',
    currency_symbol: '€',
    address: 'Via Saromelo 45',
    country: 'Italia',
    state: 'Sicilia',
    county: 'Catania',
    city: 'Acireale',
    zip_code: '95024',
    latitude: 37.6111331,
    longitude: 15.1674326,
    phone: '+39123456789',
    email: 'info@miohotel.dev',
    facebook: 'pagina-facebook',
    instagram: '#paginafacebook',
    tags_ids: [],
    pois: [],
    events_ids: [],
    services_ids: [],
  },
};

@Injectable({
  providedIn: 'root',
})
export class PropertiesApiService {
  constructor(private accommodationsService: AccomodationApiService) {}

  // [GET] properties
  getIndex(request: LoadUserPropertiesRequest, organizationId?: string) {
    return this.accommodationsService.loadAccommodations().pipe(
      map((dataAccomodation) => {
        const properties = Object.values(dataAccomodation.accommodations)
          .filter((property) => {
            if (!request.search) {
              return true;
            }

            return property.name
              .toLowerCase()
              .includes(request.search.toLowerCase());
          })
          .filter((property) => {
            if (!request.tags_ids?.length) {
              return true;
            }

            return intersection(property.tags_ids, request.tags_ids).length > 0;
          })
          .filter((property) => {
            if (!request.properties_ids?.length) {
              return true;
            }

            return request.properties_ids.includes(property.accommodationId);
          });

        const page = request.page || 1;

        return {
          data: {
            ids: properties.map((property) => property.accommodationId),
            entities: properties,
            organizationId,
          },
          meta: {
            pagination: {
              current_page: page,
              from: 25 * (page - 1) + 1,
              to: 25 * page,
              last_page: 20,
              per_page: 50,
              total: properties.length,
            },
          },
        };
      }),
    );
  }

  // [GET] properties/{propertyID}
  getDetails(propertyID: number) {
    return of({
      data: HOTELS[propertyID],
    }).pipe(delay(300));
  }

  // [GET] properties/tags
  getTags() {
    return of({
      data: [
        {
          id: 1,
          title: 'Italia',
          color: '#ff0000',
        },
        {
          id: 2,
          title: 'Mare',
          color: '#148cff',
        },
        {
          id: 3,
          title: 'Proprietario 1',
          color: '#18d91b',
        },
      ],
    }).pipe(delay(800));
  }

  // [GET] properties/statistics
  getStatistics(request: LoadPropertiesStatisticsRequest) {
    const proeprtiesStatistics = [
      {
        property: HOTELS[1],
        website_quality: 70,
        revenue: 480000,
        reservations_count: 126,
        conversions_count: 280,
        points_of_interest_count: 10,
        tags_count: 2,
        events_count: 4,
        website_url: true,
        booking_engine: true,
        parity_rate: false,
      },
      {
        property: HOTELS[2],
        website_quality: 70,
        revenue: 480000,
        reservations_count: 126,
        conversions_count: 280,
        points_of_interest_count: 10,
        tags_count: 2,
        events_count: 4,
        website_url: true,
        booking_engine: true,
        parity_rate: false,
      },
      {
        property: HOTELS[3],
        website_quality: 70,
        revenue: 480000,
        reservations_count: 126,
        conversions_count: 280,
        points_of_interest_count: 10,
        tags_count: 2,
        events_count: 4,
        website_url: true,
        booking_engine: true,
        parity_rate: false,
      },
    ].filter((proeprtyStatistics) => {
      let valid = true;

      if (request.properties_ids) {
        valid =
          valid &&
          request.properties_ids.includes(proeprtyStatistics.property.id);
      }

      if (request.search) {
        valid =
          valid &&
          proeprtyStatistics.property.name
            .toLowerCase()
            .includes(request.search.toLowerCase());
      }

      return valid;
    });

    const { data, pagination } = paginate(
      proeprtiesStatistics,
      request.page || 1,
      request.items_per_page || 10,
    );

    return of({
      data,
      meta: {
        pagination,
      },
    }).pipe(delay(300));
  }

  // [PUT] properties/{propertyID}
  update(propertyID: number, propertyChanges: Partial<Property>) {
    return of({
      data: [],
    }).pipe(delay(300));
  }
}
