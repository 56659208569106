import { createAction, props } from '@ngrx/store';
import { EffectHooks, User } from '@booking-booster-client/models';

export const loadRequest = createAction(
  '[User] Load Request',
  props<{ hooks?: EffectHooks }>()
);

export const loadSuccess = createAction(
  '[User] Load Success',
  props<{ user: User }>()
);

export const loadFailure = createAction(
  '[User] Load Failure',
  props<{ error: unknown }>()
);
