export interface User {
  email: string;
  name: string;
  pictureUrl: string;
  surname: string;
  userId: string;
  isSystem: boolean;
  permissions: any; //TODO
}

export interface UserInList extends Omit<User, 'pictureUrl'> {}
