import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private httpClient: HttpClient) {}

  load() {
    return of({
      id: 1,
      name: 'Sebastiano',
      surname: 'Maesano',
      username: 'sebastiano.maesano',
      session: {
        organization: {
          id: 1,
          name: 'Revenue Italia Holding Srl',
        },
      },
    }).pipe(delay(1000));
  }
}
